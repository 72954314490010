import { FC } from "react";
import OrgChartComponent from "./components/OrgChart";
import { useIntl } from "react-intl";
import { Row } from "react-bootstrap";

const Hierarchy: FC = () => {
    const intl = useIntl();

    return (
        <div  style={{ padding: '120px 0px 25px' }}>
            
            
            <div className="orgcontainer">
            <Row className="text-center org-section" >
                <div>
                    <h1 style={{fontWeight:'bolder'}}>{intl.formatMessage({ id: "Hierarchy" })}</h1>
                    <p>{intl.formatMessage({ id: "HierarchyDescripton" })}</p>
                </div>
            </Row>
                <OrgChartComponent />
            </div>
        </div>
    );
};

export default Hierarchy;
