import React, { FC, createContext, useContext, useEffect } from "react";

const SELECTED_MODE ="selected_mode";

type Props = {
  selectedMode: "light" | "dark";
};
const initialState: Props = {
  selectedMode: "light",
};

function getConfig(): Props {
  const mode = localStorage.getItem(SELECTED_MODE);
  if (mode) {
    try {
      return JSON.parse(mode) as Props;
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

export function setMode(mode: string) {
  localStorage.setItem(SELECTED_MODE, JSON.stringify({ selectedMode: mode }));
  window.location.reload();
}

const ModeContext = createContext<Props>(initialState);

function useMode() {
  return useContext(ModeContext).selectedMode;
};

interface Iprop {
  children?: React.ReactNode;
}

const ModeProvider: FC<Iprop> = ({ children }) => {
  const initialState = getConfig();
  
  useEffect(() => {
    if (initialState) {
      document.documentElement.setAttribute('mode', initialState.selectedMode)
    }
  }, [initialState]);

  return <ModeContext.Provider value={initialState}>{children}</ModeContext.Provider>;
};

export { ModeProvider, useMode };
